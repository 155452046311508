import React from 'react'
import { graphql } from 'gatsby'
// import Layout from '../components/layout'

export const query = graphql`
  query($id: ID!) {
    wpgraphql {
      post(id: $id) {
        title
        content
      }
    }
  }
`

const PostTemplate = ({ data }) => {
  const post = data.wpgraphql.post
  
  console.log(post);
  return (
    <>
      <h1 dangerouslySetInnerHTML={{ __html: post.title }} />
      <div dangerouslySetInnerHTML={{ __html: post.content }} />
      {console.log(post)}
    </>
  )
}

export default PostTemplate
